import { useEffect } from "react";
import PageComponent from "../../components/PageComponent";
import TEXT from "../../config/text";
import "./_referring-pages.scss";
import useTranslation from "../../hooks/useTranslation";

export default function PrivacyPolicyPage() {
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.privacy?.[text]) {
      return translation?.pages?.privacy?.[text];
    }
    return TEXT?.pages?.privacy?.[text];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageComponent>
      <div className="referring-pages">
        {/*<div>
                    <h1>PRIVACY POLICY</h1>
                    <p>
                        {appInfo.app_name} IPTV built the {appInfo.app_name} IPTV Player app as a
                        Commercial app. This SERVICE is provided by {appInfo.app_name} IPTV and is
                        intended for use as is. This page is used to inform visitors regarding our
                        policies with the collection, use, and disclosure of Personal Information if
                        anyone decided to use our Service. If you choose to use our Service, then
                        you agree to the collection and use of information in relation to this
                        policy. The Personal Information that we collect is used for providing and
                        improving the Service. We will not use or share your information with anyone
                        except as described in this Privacy Policy. The terms used in this Privacy
                        Policy have the same meanings as in our Terms and Conditions, which is
                        accessible at https://iptvplayer.in unless otherwise defined in this Privacy
                        Policy. Information Collection and Use For a better experience, while using
                        our Service, we may require you to provide us with certain personally
                        identifiable information, including but not limited to SmartTV MAC address,
                        email, first name, last name, address, phone number. The information that we
                        request will be retained by us and used as described in this privacy policy.
                        The app does use third party services that may collect information used to
                        identify you. Log Data We want to inform you that whenever you use our
                        Service, in a case of an error in the app we collect data and information
                        (through third party products) on your SmartTV called Log Data. This Log
                        Data may include information such as your device Internet Protocol IP
                        address, MAC address, device name, operating system version, the
                        configuration of the app when utilizing our Service, the time and date of
                        your use of the Service, and other statistics. Cookies Cookies are files
                        with a small amount of data that are commonly used as anonymous unique
                        identifiers. These are sent to your browser from the websites that you visit
                        and are stored on your device's internal memory. This Service does not use
                        these cookies explicitly. However, the app may use third party code and
                        libraries that use cookies to collect information and improve their
                        services. You have the option to either accept or refuse these cookies and
                        know when a cookie is being sent to your device. If you choose to refuse our
                        cookies, you may not be able to use some portions of this Service. Service
                        Providers We may employ third-party companies and individuals due to the
                        following reasons:
                    </p>

                    <ul>
                        <li>To facilitate our Service;</li>
                        <li>To provide the Service on our behalf;</li>
                        <li>To perform Service-related services; or</li>
                        <li>To assist us in analyzing how our Service is used.</li>
                    </ul>

                    <p>
                        We want to inform users of this Service that these third parties have access
                        to your Personal Information. The reason is to perform the tasks assigned to
                        them on our behalf. However, they are obligated not to disclose or use the
                        information for any other purpose. Security We value your trust in providing
                        us your Personal Information, thus we are striving to use commercially
                        acceptable means of protecting it. But remember that no method of
                        transmission over the internet, or method of electronic storage is 100%
                        secure and reliable, and we cannot guarantee its absolute security. Links to
                        Other Sites This Service may contain links to other sites. If you click on a
                        third-party link, you will be directed to that site. Note that these
                        external sites are not operated by us. Therefore, we strongly advise you to
                        review the Privacy Policy of these websites. We have no control over and
                        assume no responsibility for the content, privacy policies, or practices of
                        any third-party sites or services. Changes to This Privacy Policy We may
                        update our Privacy Policy from time to time. Thus, you are advised to review
                        this page periodically for any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page. These changes are effective
                        immediately after they are posted on this page. Contact Us If you have any
                        questions or suggestions about our Privacy Policy, do not hesitate to
                        contact us at iniptvplayer@gmail.com
                    </p>
                </div>

                <div style={{ marginTop: "30px", borderTop: "1px solid white", paddingTop: 30 }}>
                    <div>
                        <h1>TERMS OF ONLINE SALE</h1>
                        <p>
                            We invite you to read these terms and conditions of online sales of{" "}
                            {appInfo.app_name} IPTV site : https://iptvplayer.in The validation of
                            your online transaction form is considered as an irrevocable acceptance
                            of these terms. Definition : The {appInfo.app_name}� defines the
                            website https://api.iptvplayer.in that is owned and operated by the
                            company {appInfo.app_name} IPTV company.
                        </p>
                    </div>

                    <div>
                        <h1>1. Preamble</h1>
                        <p>
                            The following online sales terms govern all transactions established
                            through the {appInfo.app_name} IPTV Website . Any transaction processed
                            through this web site assumes the unconditional and irrevocable
                            acceptance of these conditions by the customer.
                        </p>

                        <h1>2. Purpose</h1>
                        <p>
                            These terms are intended to define the rights and obligations of the
                            parties in the context of the online sales done via the{" "}
                            {appInfo.app_name} IPTV Website.
                        </p>
                    </div>

                    <div>
                        <h1>3. Sales</h1>

                        <p>
                            The customer connects to the {appInfo.app_name} IPTV Website and selects
                            subscriptions that he wishes to buy. He then fills his identity
                            information, accepts the terms and clicks on the “Pay” button. The
                            customer is thus redirected to the Centre Monétique Interbancaire online
                            secure payment platform where he completes his payment information and
                            validates the payment. All of our Sales are final and no refunds are
                            allowed even after the termination of the app and or the activation of
                            the user’s account. The {appInfo.app_name} IPTV Website reserves the
                            right to terminate any user s activation at any time and without
                            providing any reason for the termination.
                        </p>
                    </div>

                    <div>
                        <h1>4. Payment Methods</h1>

                        <p>
                            To pay on Internet by credit card, the customer is called to enter his
                            payment card credentials on the secure payment page. When the
                            transaction is accepted by the bank, the settlement to debit his account
                            is made in the working-day following the date of the transaction
                            confirmation. Online payment with credit cards is secured by the Centre
                            Monetique Interbancaire that provides a fully secure payment service.
                            The customer guarantees the {appInfo.app_name} IPTV Website and the
                            company {appInfo.app_name} IPTV that he has enough funds required when
                            using the payment method chosen by him during the payment validation.
                            When paying by credit card, the conditions of this payment method that
                            are contained in the agreements established between the customer and his
                            issuer bank, and between the {appInfo.app_name} IPTV Website and his
                            bank, are applied.
                        </p>
                    </div>

                    <div>
                        <h1>5. Data Privacy</h1>

                        <p>
                            The information requested from the customer during the online payment
                            are required to process the online transaction. They are treated
                            confidentially. The customer has the right to consult or rectify his
                            personal data by sending a request electronic mail to the following
                            email address iniptvplayer@gmail.com
                        </p>
                    </div>

                    <div>
                        <h1>6. Proof of payment</h1>

                        <p>
                            The data stored on the Centre Monetique Interbancaire online platform
                            for the account of the {appInfo.app_name} IPTV Website are considered as
                            proof of payment for the online transaction made by the customer.
                        </p>
                    </div>
                </div>

                <div style={{ marginTop: "30px", borderTop: "1px solid white", paddingTop: 30 }}>
                    <h1>TERMS OF USE</h1>

                    <p>
                        The application {appInfo.app_name} IPTV MediaPlayer is delivered without any
                        content. In addition, no content is provided from the application even after
                        activating it on our website. The information displayed at the beginning
                        includes the MAC address as well as the website so that clients can activate
                        the application once for life. The {appInfo.app_name} IPTV MediaPlayer app
                        has a free 5-days trial period once installed on your smart TV to test the
                        features of the app. The MAC address and the URL are essential for the
                        proper functioning of the application. IPTV MediaPlayer does not provide
                        any content or subscription. The content and subscriptions provided under
                        the {appInfo.app_name} IPTV MediaPlayer app name are frauds.{" "}
                        {appInfo.app_name} IPTV MediaPlayer disclaims any responsibility for the use
                        of fraudulent content. The application
                        {appInfo.app_name} IPTV MediaPlayer or it is entitled can not be held as
                        responsible for the quality or interruptions of the content used on
                        {appInfo.app_name} IPTV MediaPlayer, IPTV MediaPlayer only provides a
                        media player. The application {appInfo.app_name} IPTV MediaPlayer does not
                        provide any advertising for content providers or subscriptions and reserves
                        the right to sue anyone who uses our name or logo for advertising purposes.
                        Supported {appInfo.app_name} IPTV MediaPlayer Devices can be activated after
                        one time purchace of 110MAD (about 10.10 Euros) once for lifetime
                    </p>

                    <div className="red-section">
                        <p>
                            After accepting the above conditions, no refund will be granted under
                            any circumstances. <br /> <b>Note:</b> If you are using a stolen card,
                            your mac will be banned and its IP address will be reported.
                        </p>
                    </div>

                    <p>
                        App Name {appInfo.app_name} IPTV & {appInfo.app_name} IPTV are owned and
                        operated by {appInfo.app_name}
                        IPTV which reserves the right to restrict access to the app change its
                        pricing and modify the terms of use at any time without the consent of its
                        user. It is the user s responsibility to stay up to date with the terms.
                    </p>
                </div> */}

        <div>
          <h1>{handleText("title")}</h1>
          <div dangerouslySetInnerHTML={{ __html: handleText("content") }} />
        </div>
      </div>
    </PageComponent>
  );
}
